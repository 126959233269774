#mpy-header {
    text-align: left;
    background: white;
    height: 75px;
    max-height: 77px;
  
    div:nth-child(0) {
      height: 60px;
    }
  
    img {
      margin-top: .5em;
      height: 1.5em;
    }
  
    span {
      font-size: 36px;
      color: $mpy-dark-blue;
    }
  }
  
  #mpy-footer {
    font-size: .784em;
    height: 40px;
    text-align: center;
    padding-top: 20px;
    width: 100%;
    //background: red;
  }
  .clearFix {
    clear:both;
  }

  #inactivity-header {
    .modal-header {
      background: $logo-light-blue;
      color: white;
    }
  }
  
  $mpy-navbar-margin: 15px;
  $mpy-navbar-height: 60px;
  $mpy-navbar-lineheight: 60px;
  $mpy-navbar-buttonheight: 21px;
  
  #mpy-navbar {
    max-height: 60px;
  
    .navbar-default {
      background-image: linear-gradient(180deg,#fff 0,#f8f8f8);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffffff",endColorstr="#fff8f8f8",GradientType=0);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
      border-radius: 4px;
      box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 5px rgb(0 0 0 / 8%);
    }
  
    img {
      margin: 10px 20px auto auto;
      height:3em;
    }
  
    .container {
      margin: auto 0;
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .navbar-currentItem {
      font-weight: bolder;
      color: $mpy-silver;
    }
  
    .cg-username {
      color: $mpy-blue;
      font-weight: bold;
    }
  
    .navbar {
      min-height: $mpy-navbar-height;
    }
  
    .navbar-brand {
      padding: 0 15px;
      height: $mpy-navbar-height;
      line-height: $mpy-navbar-lineheight;
    }
  
    .navbar-toggle {
      /* (120px - button height 34px) / 2 = 43px */
      margin-top: 21px;
      padding: 5px 5px !important;
    }
  
    @media (min-width: 768px) {
      .navbar-nav > li > a {
        /* (120px - line-height of 27px) / 2 = 46.5px */
        padding-top: 26.5px;
        padding-bottom: 26.5px;
        line-height: 20px;
      }
    }
  }

  

/**************
 *  Shared Controls
 */
// Time Control
.mpy-timecontrol {

    .dropdown-menu {
      min-width: 0;
    }
  
    .dropdown-menu > li > a {
      font-size: 13px;
      text-align: right;
    }
  }
  
  .spinner {
    margin-top: 20px;
    text-align: center;
    height: 100px;
  
    img {
      text-align: center;
    }
  }
  
  /**********************
   * The following CSS is all for the LabeledEditField
   */
  .mpy-input {
    .inputErr {
      border: solid 1px $mpy-dark-alert;
    }
  
    label {
      display: block;
      position: relative;
      z-index: 2;
      font-size: 11px;
      font-weight: 600;
      line-height: 1.2;
      color: $mpy-gray;
      text-transform: uppercase;
      margin-bottom: 2px;
      -webkit-transition: all $mpy-label-duration;
  //    -ms-transition: all $mpy-label-duration;
      transition: all $mpy-label-duration;
      -webkit-transform: $mpy-label-initial;
      -ms-transform: $mpy-label-initial;
      transform: $mpy-label-initial;
      opacity: 0;
    }
    input {
      -webkit-appearance: none;
      //width: 100%;
      font-size: 14px;
      margin: 0 10px 10px 0;
      padding: 10px 20px;
      border: $mpy-input-border;
      border-radius: 5px;
  
      &:focus {
        border-color: $mpy-focused;
        outline: 0 none;
      }
      &::-webkit-input-placeholder {
        text-transform: uppercase;
        color: $mpy-light-gray;
      }
      &:-moz-placeholder {
        text-transform: uppercase;
        color: $mpy-light-gray;
      }
      &::-moz-placeholder {
        text-transform: uppercase;
        color: $mpy-light-gray;
      }
      &:-ms-input-placeholder {
        text-transform: uppercase;
        color: $mpy-light-gray;
      }
    }
  }
  
  // For some reason this doesn't work if this CSS is inside .mpy-input
  // wrapper above
  .mpy-input label.mpyShowLabel {
      transform: translateY(0);
      opacity: 1;
  }
  /* End of LabeledEditField CSS */
  
  /***************************
   *
   * End of shared controls
   */
